import React from "react";

const DecorationLeftLarge = () => {
  return (
    <svg width="2656" height="1794" viewBox="0 0 2656 1794" fill="none">
      <path d="M2656 21H1116L28.5 1793" stroke="#184179" className="line" />
      <path d="M2656 39H1077.5L1 1793.5" stroke="#184179" className="line" />
      <path d="M2656 1H1156L54.5 1793.5" stroke="#184179" className="line" />
    </svg>
  );
};

export default DecorationLeftLarge;
