import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/BrandsStackMediaStatic";

const DeferSection = loadable(() =>
  import("../../components/sections/BrandsStackMediaDefer")
);

const StackMedia = () => (
  <Layout>
    <Seo
      title="StackMedia Advertising Solutions"
      description="We help marketers get more value from their advertising dollars. Reach targeted audiences at scale with our publisher network."
    />

    <LazyLoadSection static={<StaticSection />}>
      <DeferSection />
    </LazyLoadSection>
  </Layout>
);

export default StackMedia;
