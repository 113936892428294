import React from "react";
import styled from "styled-components";
import { textBlack1, white } from "./Colors";
import { mediaMedium } from "./variables";

const Outline = styled.span`
  color: ${(props) => props.color ?? textBlack1};
  -webkit-text-fill-color: ${(props) => props.fill ?? white};
  -webkit-text-stroke-width: ${(props) => props.size ?? "1px"};
  -webkit-text-stroke-color: ${(props) =>
    props.stroke ?? props.color ?? textBlack1};

  @media (${mediaMedium}) {
    -webkit-text-stroke-width: ${(props) => props.size ?? "2px"};
  }
`;

const OutlinedText = ({
  color,
  fill,
  stroke,
  size,
  as,
  component,
  children,
  inline
}) => {
  const Element = component ?? "<span />";
  const styles = inline ? { display: "inline" } : {};

  return (
    <Element style={styles} as={as}>
      <Outline color={color} fill={fill} stroke={stroke} size={size}>
        {children}
      </Outline>
    </Element>
  );
};

export default OutlinedText;
