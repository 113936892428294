import React from "react";
import { Headline80 } from "./styles/Headings";
import PrimaryButton from "./PrimaryButton";
import styled from "styled-components";
import { mediaMedium } from "./styles/variables";

const Wrapper = styled.div`
  position: relative;
  z-index: 4;
`;

const TitleWrapper = styled.div`
  max-width: ${(props) => props.theme.titleWidth ?? "570px"};
  margin-bottom: 22px;
  text-transform: uppercase;

  @media (${mediaMedium}) {
    margin-bottom: 40px;
  }
`;

const MainHeroTitle = ({ children, label, link, cleanTitle, theme, as }) => {
  const Title = cleanTitle ? "div" : Headline80;
  return (
    <Wrapper>
      <TitleWrapper theme={theme}>
        <Title as="h1">{children}</Title>
      </TitleWrapper>

      {label && <PrimaryButton attrs={link}>{label}</PrimaryButton>}
    </Wrapper>
  );
};

export default MainHeroTitle;
