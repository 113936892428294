import React from "react";
import styled from "styled-components";
import { black1, brandColor3 } from "./Colors";
import { mediaMedium } from "./variables";

const Text = styled.strong`
  font-weight: 800;
  position: relative;
  color: ${(props) => props.theme.color ?? black1};
  box-shadow: inset 0 -0.325em ${(props) => props.theme.background ?? "red"},
    inset 0 -0.525em ${brandColor3};
  display: inline;

  span {
    position: relative;
    z-index: 2;
  }

  @media (${mediaMedium}) {
    &:before {
      height: 8px;
      bottom: 9px;
    }
  }
`;

const TextHighlight = ({ theme, children }) => {
  return (
    <Text theme={theme}>
      <span>{children}</span>
    </Text>
  );
};

export default TextHighlight;
