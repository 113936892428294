import React from "react";
import styled, { keyframes } from "styled-components";
import Image from "./Image";

import omit from "lodash/omit";
import { Link } from "gatsby";
import {
  brandColor1,
  brandColor1Shade,
  brandColor1Tint,
  gray2,
  white
} from "./styles/Colors";

import iconArrowBrandColor1Shade from "../images/9-cta-arrow-brand-color-1-shade.svg";
import iconArrowBrandColor1Tint from "../images/9-cta-arrow-brand-color-1-tint.svg";
import iconArrowWhite from "../images/9-cta-arrow-white.svg";

const VARIANTS = {
  shade: {
    color: brandColor1Shade,
    icon: iconArrowBrandColor1Shade,
    hoverColor: brandColor1
  },
  tint: {
    color: brandColor1Tint,
    icon: iconArrowBrandColor1Tint,
    hoverColor: brandColor1
  },
  white: {
    color: white,
    icon: iconArrowWhite,
    hoverColor: gray2
  }
};

const Arrow = styled(Image)`
  margin-left: 19px;
  transition: 0.3s transform;
`;

const Animation = keyframes`
  to {transform: scale(1) translateX(0%)}
  50% {transform: scale(1.2) translateX(10%)}
  from {transform: scale(1) translateX(0%)}
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => VARIANTS[props.variant].color};
  transition: 0.3s color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${(props) => VARIANTS[props.variant].hoverColor};

    ${Arrow} {
      animation: ${Animation} 1s linear infinite;
    }
  }
`;

const ArrowButton = ({ attrs, large, children, variant = "shade" }) => {
  const opts = omit(attrs, ["as"]);
  opts.as = (attrs && attrs.as) || (attrs && attrs.to) ? attrs.as : "span";
  return (
    <Button {...opts} variant={variant}>
      <span>{children}</span>{" "}
      <Arrow
        alt=""
        src={VARIANTS[variant].icon}
        width={large ? 36 : 22}
        height={large ? 17 : 11}
      />
    </Button>
  );
};

export default ArrowButton;
