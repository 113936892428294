import React from "react";
import styled from "styled-components";
import { Eyebrows16 } from "./styles/Eyebrows";
import { Body28 } from "./styles/BodyTypes";
import Container from "./styles/Container";
import { mediaMedium } from "./styles/variables";
import { brandColor2, white } from "./styles/Colors";
import ArrowButton from "./ArrowButton";
import LinesFromRight from "./LinesFromRight";
import AnimatedLineSvg from "./AnimatedLineSvg";
import DecorationLeftLarge from "./snippets/DecorationLeftLarge";

const Wrapper = styled.div`
  padding: 24px 0 58px;
  position: relative;
  overflow: hidden;
  background: ${(props) => props.theme.background ?? white};

  @media (${mediaMedium}) {
    padding: 120px 0 116px;
  }
`;

const ContainerBox = styled(Container)`
  position: relative;

  @media (${mediaMedium}) {
    display: flex;
    justify-content: flex-end;
  }
`;

const Box = styled.div`
  max-width: ${(props) => props.width ?? "528px"};

  > div {
    p {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.span`
  display: block;
  margin-top: 24px;

  @media (${mediaMedium}) {
    margin-top: 67px;
  }
`;

const RowButton = styled.span`
  display: block;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  @media (${mediaMedium}) {
    &:not(:last-child) {
      margin-bottom: 23px;
    }
  }
`;

const Decoration = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50px;
  left: -16%;
  pointer-events: none;
`;

const Title = styled(Eyebrows16)`
  margin-bottom: 38px;
`;

const AnimatedWrapper = styled.div`
  position: absolute;
  width: 2656px;
  height: 100%;
  top: -52px;
  pointer-events: none;

  @media (max-width: 1079px) {
    right: -80%;
  }

  @media (max-width: 980px) {
    right: -90%;
  }

  @media (max-width: 890px) {
    right: -115%;
  }

  @media (min-width: 1080px) {
    left: -60%;
    right: initial;
  }
`;

const HeroDivideRight = ({
  contentTag,
  links,
  theme,
  title,
  children,
  staticLines
}) => {
  return (
    <Wrapper theme={theme}>
      {theme && theme.background && (
        <Decoration>
          <LinesFromRight
            color={theme && theme.lines ? theme.lines : brandColor2}
          />
        </Decoration>
      )}

      <ContainerBox>
        {(!theme || (theme && !theme.background)) && (
          <AnimatedWrapper>
            <AnimatedLineSvg
              static={staticLines}
              triggerOffset={-300}
              svgWidth="100%"
            >
              <DecorationLeftLarge />
            </AnimatedLineSvg>
          </AnimatedWrapper>
        )}
        <Box theme={theme}>
          {title && <Title>{title}</Title>}
          <Body28 theme={theme} as={contentTag ?? "p"}>
            {children}
            {links && (
              <Row>
                {links.map((link) => {
                  return (
                    <RowButton key={link.label}>
                      <ArrowButton attrs={link.args} large={true}>
                        {link.label}
                      </ArrowButton>
                    </RowButton>
                  );
                })}
              </Row>
            )}
          </Body28>
        </Box>
      </ContainerBox>
    </Wrapper>
  );
};

export default HeroDivideRight;
