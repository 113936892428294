import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { brandColor2, gray4 } from "../styles/Colors";
import HeroTitleOutline from "../HeroOutlineTitle";
import HeroDivideRight from "../HeroDivideRight";
import TextHighlight from "../styles/TextHighlight";
import StackMediaLanding from "../brands/StackMediaLanding";

const BrandsStackMediaStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "65-brands-ads-landing.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <StackMediaLanding image={images.file} />

      <HeroTitleOutline
        theme={{
          background: gray4,
          fill: gray4,
          paddingBottom: "0px",
          width: "640px"
        }}
        title="WHY"
        subtitle="STACKMEDIA ?"
      />

      <HeroDivideRight
        theme={{ background: gray4, lines: brandColor2 }}
        contentTag={"div"}
      >
        <p>
          We believe you should only pay for advertising that drives real
          results.
        </p>
        <p>
          That’s why we threw out the traditional CPM model, and built the first
          and only{" "}
          <TextHighlight theme={{ background: gray4 }}>
            CPC performance-based
          </TextHighlight>{" "}
          sponsored content platform, delivering premium articles, email, +
          native ad placements at scale.
        </p>
      </HeroDivideRight>
    </>
  );
};

export default BrandsStackMediaStatic;
