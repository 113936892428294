import React from "react";
import styled from "styled-components";
import { linesSkew, mediaMedium } from "./styles/variables";
import { brandColor2 } from "./styles/Colors";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  pointer-events: none;
`;

const Line1 = styled.span`
  @media (${mediaMedium}) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 35%;
    transform: skewX(${(props) => props.skew ?? linesSkew});
    border-top: 1px solid;
    border-left: 1px solid;
    border-color: ${(props) => props.color ?? brandColor2};
  }
`;

const Line2 = styled(Line1)`
  @media (${mediaMedium}) {
    top: 18px;
    left: calc(35% - 32px);
  }
`;

const Line3 = styled(Line1)`
  @media (${mediaMedium}) {
    top: 36px;
    left: calc(35% - 64px);
  }
`;

const LinesFromRight = ({ color, skew }) => {
  return (
    <Wrapper>
      <Line1 skew={skew} color={color} />
      <Line2 skew={skew} color={color} />
      <Line3 skew={skew} color={color} />
    </Wrapper>
  );
};

export default LinesFromRight;
