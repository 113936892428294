import React from "react";
import styled from "styled-components";
import { Headline64 } from "./styles/Headings";
import Container from "./styles/Container";
import { mediaMedium } from "./styles/variables";
import OutlinedText from "./styles/OutlinedText";
import { white } from "./styles/Colors";

const Background = styled.div`
  background: ${(props) => props.theme.background ?? white};
  padding: ${(props) => props.theme.padding ?? 0};
`;

const Wrapper = styled(Container)`
  padding-top: ${(props) => props.theme.paddingTopMobile ?? "0px"};

  @media (${mediaMedium}) {
    margin-top: ${(props) => props.theme.marginTop ?? "-100px"};
    margin-bottom: ${(props) => props.theme.marginBottom ?? "0px"};
    position: relative;
    z-index: 2;
    padding-bottom: ${(props) => props.theme.paddingBottom ?? "0"};
    padding-top: ${(props) => props.theme.paddingTop ?? "0px"};
  }
`;

const Box = styled.div`
  max-width: ${(props) => props.theme.width ?? "400px"};
  padding: 16px 0;
`;

const TitleOutline = ({ title, theme, subtitle, inline }) => {
  return (
    <Background theme={theme}>
      <Wrapper theme={theme}>
        <Box theme={theme}>
          <Headline64 theme={theme}>
            {title}{" "}
            <OutlinedText
              inline
              color={theme && theme.color ? theme.color : undefined}
              fill={theme && theme.background ? theme.background : undefined}
              as="span"
              component={Headline64}
            >
              {subtitle}
            </OutlinedText>
          </Headline64>
        </Box>
      </Wrapper>
    </Background>
  );
};

export default TitleOutline;
